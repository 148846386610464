import { AuthType } from '@CPEnums'

export default defineNuxtRouteMiddleware(() => {
  const { $auth } = useNuxtApp()
  const localePath = useLocalePath()

  if ($auth.user.value?.authType === AuthType.booking) {
    return localePath({
      name: 'profile-bookings-bookingId',
      params: { bookingId: $auth.user.value.bookingId },
    })
  }
})
